













































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

// OAuth Flow:
// 1: Api POST /business/:BID/stripe_connect
// 2: Redirect to connect.stripe.com/…?state=<business.stripe_connect_state>
// 3: Authorize and return to this page
// 4: Api PUT /business/:BID/account with `code` and `state`

export default Vue.extend({
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null as Error|null,
      loading: false,
      state: '',
      waiting: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
    }),
    BUSINESS(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
    url(): string|null {
      if (!this.state) return null;

      const base = 'https://connect.stripe.com/oauth/authorize';
      const params: Record<string, string> = {
        response_type: 'code',
        client_id: process.env.VUE_APP_STRIPE_CA || 'ca_undefined',
        scope: 'read_write',
        stripe_landing: 'login',
        always_prompt: 'true',
        state: this.state,
        redirect_uri: `${process.env.VUE_APP_BASE_URL}/.stripe/oauth-connect`,
      };

      const query = new URLSearchParams(params);

      return `${base}?${query}`;
    },
  },
  watch: {
    '$route.query.code': {
      immediate: true,
      handler(to) {
        if (this.waiting) return;
        if (!this.BUSINESS.has_standalone_account) {
          if (to) this.authorize();
          else this.prepare();
        }
      },
    },
    'BUSINESS.has_standalone_account': {
      immediate: false,
      handler(to) {
        if (to === false) this.prepare();
        this.waiting = false;
      },
    },
  },
  methods: {
    async prepare() {
      const state = await this.$store.dispatch('business/OAuthPrepare', {
        BUSINESS_ID: this.BUSINESS.id,
      }).catch((e) => { this.error = e; });

      this.state = state;
    },
    async authorize() {
      await this.$store.dispatch('business/OAuthAuthorize', {
        BUSINESS_ID: this.BUSINESS.id,
        form: {
          ...this.$route.query,
        },
      }).then(() => {
        this.waiting = true;
      }).catch((e) => {
        this.error = e;
      }).finally(() => {
        this.$router.replace({
          name: 'dashboard.connect',
          query: {},
        });
      });
    },
    async deauthorize() {
      this.loading = true;

      await this.$store.dispatch('business/OAuthDeauthorize', {
        BUSINESS_ID: this.BUSINESS.id,
      }).catch((e) => { this.error = e; });

      this.loading = false;

      this.waiting = true;
    },
  },
});
