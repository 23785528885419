import { render, staticRenderFns } from "./Connect.vue?vue&type=template&id=f8a05ffe&scoped=true&"
import script from "./Connect.vue?vue&type=script&lang=ts&"
export * from "./Connect.vue?vue&type=script&lang=ts&"
import style0 from "./Connect.vue?vue&type=style&index=0&id=f8a05ffe&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8a05ffe",
  null
  
)

/* custom blocks */
import block0 from "./Connect.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./Connect.vue?vue&type=custom&index=1&blockType=i18n&lang=yaml&locale=de"
if (typeof block1 === 'function') block1(component)

export default component.exports